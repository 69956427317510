import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-user/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ArticleCard = makeShortcode("ArticleCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The guides below are intended to get you up and running with the KOPE web app and specific workflows as quickly as possible.
Choose the right guide for you below.`}</p>
    </PageDescription>
    <Row mdxType="Row">
  <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ArticleCard subTitle="1. Getting Started with KOPE" title="Quick Start: Get going fast with the basics of everything KOPE" actionIcon="arrowRight" href="/getting-started" mdxType="ArticleCard" />    
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ArticleCard subTitle="1. KOPE User Guide" title="Deep dive: Explore advanced features with our comprehensive user guide" actionIcon="arrowRight" href="/kope" mdxType="ArticleCard" />    
  </Column>
    </Row>
    <h4>{`Not what you were after? See our other guides:`}</h4>
    <ul>
      <li parentName="ul">{`For `}<em parentName="li">{`feedback`}</em>{` or our `}<em parentName="li">{`roadmap`}</em>{`, check out our `}<a parentName="li" {...{
          "href": "'https://feedback.kope.ai/'"
        }}>{`KOPE Feedback Portal`}</a>{`.`}</li>
      <li parentName="ul">{`For `}<em parentName="li">{`software development`}</em>{` on KOPE, head over to our `}<a parentName="li" {...{
          "href": "'https://docs.kope.ai/quick-start'"
        }}>{`KOPE SDK Docs`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      